<template>
  <v-form data-vv-scope="form-event-settings" key="eventSettings">
    <v-row class="align-center">
      <v-col cols="12" sm="5" md="5">
        <v-card color="#212121" dark v-if="fullDatetime">
          <v-list-item two-line data-testid="event-original-timezone">
            <v-list-item-content>
              <v-list-item-title class="subtitle-1">
                <span style="color: rgba(255, 255, 255, 0.7)"
                  >Original time zone:
                </span>
                <span>{{ timezone }}</span>
              </v-list-item-title>
              <v-list-item-subtitle
                ><span>Start: </span
                ><span class="font-weight-bold">{{
                  activeEvent.startDateTime
                    | dateFormatTimezone(timezone, "LLLL")
                }}</span></v-list-item-subtitle
              >
              <v-list-item-subtitle
                ><span>End: </span
                ><span class="font-weight-bold">{{
                  activeEvent.endDateTime | dateFormatTimezone(timezone, "LLLL")
                }}</span></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <avatar
          @getCroppedImage="getCroppedImage"
          @deleteImage="icon = null"
          :image="activeEvent.icon"
          data-testid="event-icon"
        >
        </avatar>
      </v-col>
      <v-col cols="12" sm="5" md="5">
        <v-card color="#212121" dark v-if="fullDatetime">
          <v-list-item two-line data-testid="event-local-timezone">
            <v-list-item-content>
              <v-list-item-title class="subtitle-1" a>
                <span style="color: rgba(255, 255, 255, 0.7)"
                  >Local time zone:
                </span>
                <span>{{ localTimezone }}</span>
              </v-list-item-title>
              <v-list-item-subtitle
                ><span>Start: </span
                ><span class="font-weight-bold">{{
                  activeEvent.startDateTime
                    | dateFormatTimezone(localTimezone, "LLLL")
                }}</span></v-list-item-subtitle
              >
              <v-list-item-subtitle
                ><span>End: </span
                ><span class="font-weight-bold">{{
                  activeEvent.endDateTime
                    | dateFormatTimezone(localTimezone, "LLLL")
                }}</span></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="errors.has('form-event-settings.general_error_field')">
      <v-col cols="12" md="12">
        <v-alert type="error" text data-testid="event-general-errors">
          {{ errors.first("form-event-settings.general_error_field") }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" :sm="isSuperUser ? 6 : 12">
            <v-text-field
              label="Name"
              v-model="activeEvent.name"
              :error-messages="errors.collect('form-event-settings.name')"
              data-vv-name="name"
              data-vv-validate-on="change"
              v-validate="'required'"
              outlined
              data-testid="name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" v-if="isSuperUser">
            <SelectCompaniesAsync
              v-model="activeEvent.group_key"
              outlined
              label="Company"
              :multiple="false"
              item-value="_key"
              item-text="name"
              v-validate="'required'"
              :error-messages="errors.collect(`form-event-settings.group_key`)"
              data-vv-name="group_key"
              data-vv-validate-on="change"
              data-vv-as="company"
              field="group_key"
              :menu-props="{ maxHeight: 304 }"
              :disabled="editedIndex === -1 ? false : true"
              data-testid="company"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <v-textarea
              v-model="activeEvent.description"
              label="Description"
              outlined
              :error-messages="
                errors.collect('form-event-settings.description')
              "
              data-vv-name="description"
              rows="1"
              auto-grow
              data-testid="description"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12" sm="12" lg="12">
        <v-row>
          <v-col md="12" sm="12" lg="6">
            <v-row justify="space-around" justify-xl="start">
              <v-col sm="6" cols="12" lg="6" xl="4">
                <div class="pr-6 subtitle-1 font-weight-bold">
                  Time range settings
                </div>
              </v-col>
              <v-col sm="6" cols="12" lg="6" xl="4">
                <v-checkbox
                  v-model="activeEvent.is_scheduled"
                  label="Sheduled"
                  hide-details
                  class="mt-0 pt-0"
                  data-testid="chbx-is-sheduled"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row justify="space-around" justify-xl="start">
              <v-col sm="6" cols="12" lg="6" xl="4">
                <div>
                  <div class="pb-1">Start date</div>
                  <div>
                    <vc-date-picker
                      v-if="!isLoadingPage"
                      is-dark
                      mode="date"
                      v-model="activeEvent.startDateTime"
                      data-testid="start-date"
                    />
                    <div class="mt-2 ml-2">
                      <div
                        class="v-messages theme--dark error--text"
                        role="alert"
                        data-testid="start-date-errors"
                      >
                        <div class="v-messages__wrapper">
                          <div
                            class="v-messages__message message-transition-enter-to"
                          >
                            {{ errors.first("form-event-settings.start_date") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="activeEvent.is_scheduled">
                    <div>Start time</div>
                    <div>
                      <vc-date-picker
                        class="mr-4"
                        v-if="!isLoadingPage"
                        is-dark
                        mode="time"
                        v-model="activeEvent.startDateTime"
                        data-testid="start-time"
                      />
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col sm="6" cols="12" lg="6" xl="4">
                <div>
                  <div class="pb-1">End date</div>
                  <div>
                    <vc-date-picker
                      v-if="!isLoadingPage"
                      is-dark
                      mode="date"
                      v-model="activeEvent.endDateTime"
                      data-testid="end-date"
                    />
                    <div class="mt-2 ml-2">
                      <div
                        class="v-messages theme--dark error--text"
                        role="alert"
                        data-testid="end-date-errors"
                      >
                        <div class="v-messages__wrapper">
                          <div
                            class="v-messages__message message-transition-enter-to"
                          >
                            {{ errors.first("form-event-settings.end_date") }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="activeEvent.is_scheduled">
                    <div>End time</div>
                    <div>
                      <vc-date-picker
                        class="mr-4"
                        v-if="!isLoadingPage"
                        is-dark
                        mode="time"
                        v-model="activeEvent.endDateTime"
                        data-testid="end-time"
                      />
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="12" sm="12" lg="6" cols="12">
            <div class="pb-4 subtitle-1 font-weight-bold">Place settings</div>
            <v-row class="pt-6">
              <v-col md="12" sm="12" lg="12">
                <v-autocomplete
                  v-model="city"
                  :items="cities"
                  label="City"
                  item-value="city"
                  item-text="city"
                  outlined
                  :error-messages="errors.collect('form-event-settings.city')"
                  data-vv-name="city"
                  data-vv-validate-on="change"
                  data-vv-as="City"
                  return-object
                  data-testid="city"
                />
              </v-col>
              <v-col md="12" sm="12" lg="12">
                <v-text-field
                  label="Country"
                  v-model="country"
                  :error-messages="
                    errors.collect('form-event-settings.country')
                  "
                  data-vv-name="country"
                  data-vv-validate-on="change"
                  v-validate="'required'"
                  outlined
                  disabled
                  data-testid="country"
                ></v-text-field>
              </v-col>
              <v-col md="12" sm="12" lg="12">
                <v-autocomplete
                  v-model="timezone"
                  :items="timezones"
                  label="Time zone"
                  outlined
                  :error-messages="
                    errors.collect('form-event-settings.time_zone')
                  "
                  data-vv-name="time_zone"
                  data-vv-validate-on="change"
                  data-vv-as="Time zone"
                  v-validate="'required'"
                  :disabled="city ? true : false"
                  data-testid="timezone"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!--      <v-col md="12" sm="12">-->
      <!--        <div class="pb-4">Time range settings</div>-->
      <!--        <v-row>-->
      <!--          <v-col md="6" sm="12">-->
      <!--            <vc-date-picker color="orange"-->
      <!--                            is-dark-->
      <!--                            v-model="activeEvent.range"-->
      <!--                            is-range-->
      <!--                            mode="dateTime"-->
      <!--                            :timezone="activeEvent.time_zone"-->
      <!--                            v-if="!isLoadingPage"-->
      <!--            >-->
      <!--              <template v-slot="{ inputValue, inputEvents }">-->
      <!--                <div class="text-center d-flex align-top justify-space-around">-->
      <!--                  <v-text-field-->
      <!--                    label="From"-->
      <!--                    v-model="inputValue.start"-->
      <!--                    :error-messages="errors.collect('form-event-settings.start_date')"-->
      <!--                    data-vv-name="start_date"-->
      <!--                    outlined-->
      <!--                    v-on="inputEvents.start"-->
      <!--                    prepend-icon="mdi-calendar"-->
      <!--                  ></v-text-field>-->

      <!--                  <v-text-field-->
      <!--                    label="To"-->
      <!--                    v-model="inputValue.end"-->
      <!--                    :error-messages="errors.collect('form-event-settings.end_date')"-->
      <!--                    data-vv-name="end_date"-->
      <!--                    outlined-->
      <!--                    v-on="inputEvents.end"-->
      <!--                    prepend-icon="mdi-arrow-right"-->
      <!--                    class="ml-2"-->
      <!--                  ></v-text-field>-->
      <!--                </div>-->
      <!--              </template>-->
      <!--            </vc-date-picker>-->
      <!--          </v-col>-->
      <!--          <v-col md="6" sm="12">-->
      <!--            <div class="text-center d-flex align-bottom justify-space-around">-->
      <!--              <v-autocomplete-->
      <!--                v-model="activeEvent.time_zone"-->
      <!--                :items="timezones"-->
      <!--                label="Time zone"-->
      <!--                prepend-icon="mdi-earth"-->
      <!--                outlined-->
      <!--                :error-messages="errors.collect('form-event-settings.time_zone')"-->
      <!--                data-vv-name="time_zone"-->
      <!--                data-vv-validate-on="change"-->
      <!--                data-vv-as="Time zone"-->
      <!--                v-validate="'required'"-->
      <!--              />-->
      <!--            </div>-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-col>-->

      <!--      <v-col cols="12" md="3">-->
      <!--        <v-row>-->
      <!--          <v-col-->
      <!--            cols="12"-->
      <!--            sm="12"-->
      <!--            md="12"-->
      <!--          >-->
      <!--            <v-dialog-->
      <!--              ref="dialog"-->
      <!--              v-model="modal"-->
      <!--              :return-value.sync="dateRangeText"-->
      <!--              persistent-->
      <!--              width="490px"-->
      <!--            >-->
      <!--              <template v-slot:activator="{ on, attrs }">-->
      <!--                <v-text-field-->
      <!--                  v-model="dateRangeText"-->
      <!--                  label="Date range"-->
      <!--                  prepend-icon="mdi-calendar"-->
      <!--                  readonly-->
      <!--                  v-bind="attrs"-->
      <!--                  v-on="on"-->
      <!--                  outlined-->
      <!--                  :error-messages="dateRangeEvent"-->
      <!--                ></v-text-field>-->

      <!--              </template>-->
      <!--              <v-date-picker-->
      <!--                v-model="dates"-->
      <!--                range-->
      <!--                :selected-items-text="dateRangeText"-->
      <!--                full-width-->
      <!--                :title-date-format="customizeDate"-->
      <!--                :min="minimumDate"-->
      <!--                :max="maximumDate"-->
      <!--              >-->
      <!--                <v-spacer></v-spacer>-->
      <!--                &lt;!&ndash;            <v-btn&ndash;&gt;-->
      <!--                &lt;!&ndash;              text&ndash;&gt;-->
      <!--                &lt;!&ndash;              color="primary"&ndash;&gt;-->
      <!--                &lt;!&ndash;              @click="modal = false"&ndash;&gt;-->
      <!--                &lt;!&ndash;            >&ndash;&gt;-->
      <!--                &lt;!&ndash;              Cancel&ndash;&gt;-->
      <!--                &lt;!&ndash;            </v-btn>&ndash;&gt;-->
      <!--                <v-btn-->
      <!--                  text-->
      <!--                  color="primary"-->
      <!--                  @click="$refs.dialog.save(dates)"-->
      <!--                >-->
      <!--                  OK-->
      <!--                </v-btn>-->
      <!--              </v-date-picker>-->
      <!--            </v-dialog>-->
      <!--          </v-col>-->
      <!--          <v-col cols="12" sm="12">-->
      <!--            <v-dialog-->
      <!--              ref="dialogTime"-->
      <!--              v-model="modalTime"-->
      <!--              :return-value.sync="activeEvent.time"-->
      <!--              persistent-->
      <!--              width="290px"-->
      <!--            >-->
      <!--              <template v-slot:activator="{ on, attrs }">-->
      <!--                <v-text-field-->
      <!--                  v-model="activeEvent.time"-->
      <!--                  label="Time"-->
      <!--                  prepend-icon="mdi-clock-time-four-outline"-->
      <!--                  readonly-->
      <!--                  v-bind="attrs"-->
      <!--                  v-on="on"-->
      <!--                  outlined-->
      <!--                  v-validate="'required'"-->
      <!--                  :error-messages="errors.collect(`form-event-settings.time`)"-->
      <!--                  data-vv-name="time"-->
      <!--                  data-vv-validate-on="change"-->
      <!--                  data-vv-as="Time"-->
      <!--                ></v-text-field>-->
      <!--              </template>-->
      <!--              <v-time-picker-->
      <!--                v-if="modalTime"-->
      <!--                v-model="activeEvent.time"-->
      <!--                full-width-->
      <!--              >-->
      <!--                <v-spacer></v-spacer>-->
      <!--                <v-btn-->
      <!--                  text-->
      <!--                  color="primary"-->
      <!--                  @click="modalTime = false"-->
      <!--                >-->
      <!--                  Cancel-->
      <!--                </v-btn>-->
      <!--                <v-btn-->
      <!--                  text-->
      <!--                  color="primary"-->
      <!--                  @click="$refs.dialogTime.save(activeEvent.time)"-->
      <!--                >-->
      <!--                  OK-->
      <!--                </v-btn>-->
      <!--              </v-time-picker>-->
      <!--            </v-dialog>-->
      <!--          </v-col>-->
      <!--          <v-col cols="12" sm="12">-->
      <!--            <v-autocomplete-->
      <!--              v-model="activeEvent.time_zone"-->
      <!--              :items="timezones"-->
      <!--              label="Time zone"-->
      <!--              prepend-icon="mdi-earth"-->
      <!--              outlined-->
      <!--              :error-messages="errors.collect('form-event-settings.time_zone')"-->
      <!--              data-vv-name="time_zone"-->
      <!--              data-vv-validate-on="change"-->
      <!--              data-vv-as="Time zone"-->
      <!--              v-validate="'required'"-->
      <!--            />-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-col>-->
    </v-row>

    <v-row v-if="editedIndex !== -1">
      <v-col cols="12" sm="12" md="12">
        <v-alert
          :value="true"
          type="error"
          v-if="errors.has('form-event-settings.members')"
          data-testid="event-members-errors"
        >
          {{ errors.first("form-event-settings.members") }}
        </v-alert>
        <EventMembersList />
      </v-col>
    </v-row>

    <v-card-actions v-if="!fromAddBundleWizard">
      <v-spacer />
      <v-btn color="error" text to="/store/events">Close</v-btn>
      <v-btn
        v-if="editedIndex === -1"
        color="indigo"
        text
        @click.stop="saveAddMembers()"
        data-testid="btn-save-add-members"
        >Save & Add Members
      </v-btn>
      <v-btn
        v-if="editedIndex === -1"
        color="orange"
        text
        @click.stop="saveGoConnections()"
        data-testid="btn-save-go-connections"
        >Save & Go Connections
      </v-btn>
      <v-btn
        color="green"
        text
        @click.stop="saveAndClose()"
        data-testid="btn-save"
      >
        Save
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="fromAddBundleWizard">
      <v-spacer />
      <v-btn color="error" text to="/store/bundles">Close</v-btn>
      <v-btn
        v-if="editedIndex === -1"
        color="indigo"
        text
        @click.stop="saveAddMembers()"
        data-testid="btn-save-add-members-bundle"
        >Save & Add Members
      </v-btn>
      <v-btn
        v-if="editedIndex === -1"
        color="orange"
        text
        @click.stop="saveGoConnections(false)"
        data-testid="btn-save-go-connections-bundle"
        >Save & Go Connections
      </v-btn>
      <v-btn
        color="green"
        text
        @click.stop="saveAndClose('/store/bundles')"
        data-testid="btn-save-close-bundle"
      >
        Save & Close
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import _ from "lodash"
import moment from "moment"
import momentTimezone from "moment-timezone"
import { mapGetters } from "vuex"
import SelectCompaniesAsync from "@/components/selects/SelectCompaniesAsync"
import WsConnMixin from "@/mixins/websocket-connection.js"
import EventMembersList from "./EventMembersList"
import "@/plugins/v-calendar"
import GeneralMixin from "@/mixins/general.js"
import EventCommonMixin from "@/mixins/event/common.js"
import Avatar from "@/components/avatar/Avatar"
import * as cityTimezones from "city-timezones"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
    fromAddBundleWizard: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SelectCompaniesAsync,
    EventMembersList,
    Avatar,
  },

  mixins: [GeneralMixin, WsConnMixin, EventCommonMixin],

  data: () => ({
    activeActionTab: 0,
    modal: false,
    timezones: momentTimezone.tz.names(),
    dateRange: [],
    modalTime: false,
    localTimezone: momentTimezone.tz.guess(true),
    range: {
      start: new Date(2020, 0, 1),
      end: new Date(2020, 0, 5),
    },
    icon: undefined,
    cities: cityTimezones.cityMapping,
  }),

  watch: {
    dates: function (newVal) {
      if (newVal.length > 1 && moment(newVal[0]).isAfter(newVal[1], "day")) {
        this.dates = [newVal[1], newVal[0]]
      }
    },
  },

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetEventItem").then(() => {
      self.getItemData().then(() => {
        self.$store
          .dispatch("setCompanyForEvent", {
            editedIndex: self.editedIndex,
            groupKey: self.currentUser.group_key,
          })
          .then(() => {
            //self.icon = self.activeEvent.icon
            self.initWebsocketConnection()
            self.$store.dispatch("saveLoaderAction", false)
          })
      })
    })
    if (self.editedIndex === -1)
      self.$store.dispatch("setTimezoneEvent", self.localTimezone)
    self.$root.$off(["event-page-refresh", "event-member-change", "event-list"])
    // there are some changes in member statuses - we need to update them
    self.$root.$on("event-page-refresh", (response) => {
      self.$store.dispatch("refreshEventMembersStatuses", {
        slug: self.editedIndex,
      })
    })
    self.$root.$on("event-member-change", () => {
      self.getItemData()
    })
    self.$on("event-list", () => {
      self.getItemData()
    })
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Create new event"
        : `<span class="blue-grey--text text--lighten-4">Edit event</span> ${this.activeEvent.name}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getEventItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    createEvent(formData) {
      let self = this
      return new Promise((resolve, reject) => {
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.events.create",
            args: formData.data,
          })
        )

        // If is no WS callback more than 6 second - reject
        let timeoutObj = setTimeout(() => {
          reject()
        }, 8000)

        self.$on("event-created", (response) => {
          clearTimeout(timeoutObj)
          if (_.get(response, "status", "") === "success") {
            resolve(response.data)
          } else {
            self.handleValidationErrors(response, "form-event-settings")
            reject(response)
          }
        })
      })
    },
    editEvent(formData) {
      let self = this
      return new Promise((resolve, reject) => {
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.events.update",
            args: { ...formData.data, ...{ event_key: formData.slug } },
          })
        )

        // If is no WS callback more than 6 second - reject
        let timeoutObj = setTimeout(() => {
          reject()
        }, 6000)

        self.$on("event-edited", (response) => {
          clearTimeout(timeoutObj)
          if (_.get(response, "status", "") === "success") {
            resolve(response.data)
          } else {
            self.handleValidationErrors(response, "form-event-settings")
            reject(response)
          }
        })
      })
    },
    save() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$validator.errors.clear("form-event-settings")
        if (!self.activeEvent.startDateTime) {
          self.$validator.errors.add({
            scope: "form-event-settings",
            field: "start_date",
            msg: "You must select start date",
          })
        }
        // @todo clear later
        // if (!self.activeEvent.range.start) {
        //   self.$validator.errors.add({
        //     scope: 'form-event-settings',
        //     field: 'start_date',
        //     msg: 'You must select start date'
        //   })
        // }
        let isMemberWithoutRole = self.eventMembersSave.find(
          (m) => m.role === null
        )
        if (isMemberWithoutRole) {
          self.$validator.errors.add({
            scope: "form-event-settings",
            field: "members",
            msg: "There is a member for which the role is not specified",
          })
        }
        self.$validator.validateAll("form-event-settings").then((result) => {
          if (!self.activeEvent.endDateTime) {
            self.$validator.errors.add({
              scope: "form-event-settings",
              field: "end_date",
              msg: "You must select end date",
            })
          }

          // @todo clear later
          // if (!self.activeEvent.range.end) {
          //   self.$validator.errors.add({
          //     scope: 'form-event-settings',
          //     field: 'end_date',
          //     msg: 'You must select end date'
          //   })
          // }

          // // @todo refactor later for best validation with VeeValidate
          // if (!self.activeEvent.start_date || !self.activeEvent.end_date) {
          //   self.$validator.errors.add({
          //     scope: 'form-event-settings',
          //     field: 'start_date',
          //     msg: 'You must select date range'
          //   });
          // }
          self.$validator.validateAll("form-event-settings").then((result) => {
            if (result) {
              let formData = {
                data: {
                  name: self.activeEvent.name,
                  country: self.country,
                  city: self.activeEvent?.city,
                  time_zone: self.activeEvent.time_zone,
                  start_date: moment(self.activeEvent.startDateTime).unix(),
                  end_date: moment(self.activeEvent.endDateTime).unix(),
                  is_scheduled: self.activeEvent.is_scheduled,
                  description: self.activeEvent.description,
                  group_key: self.activeEvent.group_key,
                  is_started: self.activeEvent.is_started,
                  // start_date: self.fullDatetime.utcStartDateTimestamp,
                  // end_date: self.fullDatetime.utcEndDateTimestamp,
                },
              }
              let prms
              if (this.editedIndex > -1) {
                formData["slug"] = self.editedIndex
                prms = self.editEvent(formData)
              } else {
                prms = self.createEvent(formData)
              }
              prms
                .then((data) => {
                  self.$store.dispatch("saveEventItem", _.get(data, "data"))
                  let savedKey = _.get(data, "data._key")
                  self.saveIcon(savedKey).then(() => {
                    resolve(savedKey)
                  })
                })
                .catch(() => {
                  // catch error on save
                })
            }
          })
        })
      })
    },
    saveIcon(key) {
      return new Promise((resolve, reject) => {
        if (typeof this.icon !== "undefined") {
          let formData = new FormData()
          formData.append("icon", this.icon)
          return this.$store
            .dispatch("saveEventIcon", {
              slug: key,
              data: formData,
            })
            .then(() => {
              resolve()
            })
            .catch((err) => {
              console.log("Error on save event icon", err)
            })
        } else {
          resolve()
        }
      })
    },
    saveGoConnections(redirectTab = true) {
      let self = this
      self.save().then((key) => {
        self.$emit("event-saved")
        if (redirectTab) {
          self.$router
            .push({ path: `/store/event/edit/${key}/connections` })
            .catch((err) => {})
        }
      })
    },
    saveAddMembers() {
      let self = this
      self.save().then((key) => {
        self.$emit("event-saved")
        self.$router
          .push({ path: `/store/event/edit/${key}` })
          .catch((err) => {})
      })
    },
    saveAndClose(redirectUrl) {
      let self = this
      self.save().then(() => {
        let msg =
          self.editedIndex === -1
            ? `Event <strong>${self.activeEvent.name}</strong> was created successfully.`
            : `Event <strong>${self.activeEvent.name}</strong> was edited successfully.`
        let params = {
          text: msg,
          show: true,
        }
        self.close(redirectUrl).then(() => {
          self.$root.$emit("snackbar", params)
        })
      })
    },
    close(redirectUrl = "/store/events/") {
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: redirectUrl })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
    customizeDate() {
      return this.dateRangeText
    },
    getCroppedImage(file) {
      this.icon = file
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeEvent: "activeEvent",
      ordersRegisteredList: "ordersRegisteredList",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      eventMembersSave: "eventMembersSave",
      isLoadingPage: "getShowLoader",
    }),
    dateRangeText: {
      get() {
        if (this.dates[0] && this.dates[1]) {
          let momentDates = this.dates.map((d) =>
            moment(d).format("MMM D, YYYY")
          )
          return this.dates.length > 1 &&
            !moment(this.dates[0]).isSame(this.dates[1], "day")
            ? momentDates.join(" ~ ")
            : momentDates[0]
        }
        if (this.dates[0] && !this.dates[1]) {
          let momentDates = [
            moment(this.dates[0]).format("MMM D, YYYY"),
            "Not selected",
          ]
          return momentDates.join(" ~ ")
        }
        return "Not selected"
      },
      set(val) {},
    },
    isActiveConnectionsTab() {
      return this.isSuperUser && !this.activeEvent.group_key
    },
    dates: {
      get() {
        let datesArr = []
        if (this.activeEvent.start_date) {
          datesArr.push(this.activeEvent.start_date)
        }
        if (this.activeEvent.end_date) {
          datesArr.push(this.activeEvent.end_date)
        }
        return datesArr
      },
      set(val) {
        this.$store.dispatch("setDatesForEvent", val)
      },
    },
    nowDate() {
      return this.editedIndex === -1 ? moment().format("YYYY-MM-DD") : false
    },
    minimumDate() {
      if (this.dates[0] && !this.dates[1]) {
        let substractedMinDate = moment(this.dates[0]).subtract(2, "days")
        if (this.editedIndex === -1) {
          return substractedMinDate >= moment(this.nowDate)
            ? substractedMinDate.format("YYYY-MM-DD")
            : this.nowDate
        } else {
          return substractedMinDate.format("YYYY-MM-DD")
        }
      }
      return this.nowDate
    },
    maximumDate() {
      if (this.dates[0] && !this.dates[1]) {
        return moment(this.dates[0]).add(2, "days").format("YYYY-MM-DD")
      }
      return null
    },
    dateRangeEvent: function () {
      let startDateErr = this.errors.collect(`form-event-settings.start_date`)
      let endDateErr = this.errors.collect(`form-event-settings.end_date`)
      return [...startDateErr, ...endDateErr]
    },
    fullDatetime() {
      if (
        this.activeEvent.range.start &&
        this.activeEvent.range.end &&
        this.activeEvent.time_zone
      ) {
        // if (this.activeEvent.start_date && this.activeEvent.end_date
        //   && this.activeEvent.time && this.activeEvent.time_zone) {
        // let startDateTime = `${this.activeEvent.start_date} ${this.activeEvent.time}`
        // let endDateTime = `${this.activeEvent.end_date} ${this.activeEvent.time}`
        let startDateTime = moment(`${this.activeEvent.range.start}`)
        let endDateTime = moment(`${this.activeEvent.range.end}`)

        let utcStartDate = moment
          .tz(startDateTime, this.activeEvent.time_zone)
          .utc()
        let utcEndDate = moment
          .tz(endDateTime, this.activeEvent.time_zone)
          .utc()
        let startDateLocal = moment
          .tz(utcStartDate, this.localTimezone)
          .format("LLLL")
        let endDateLocal = moment
          .tz(utcEndDate, this.localTimezone)
          .format("LLLL")
        let startDateOriginal = moment
          .tz(utcStartDate, this.activeEvent.time_zone)
          .format("LLLL")
        let endDateOriginal = moment
          .tz(utcEndDate, this.activeEvent.time_zone)
          .format("LLLL")
        return {
          utcStartDateTimestamp: startDateTime.unix(),
          utcEndDateTimestamp: endDateTime.unix(),
          startDateLocal,
          endDateLocal,
          startDateOriginal,
          endDateOriginal,
        }
      }
      return null
    },
    city: {
      get() {
        if (
          this.activeEvent?.city &&
          typeof this.activeEvent?.city === "string"
        ) {
          const cityLookup = cityTimezones.lookupViaCity(this.activeEvent?.city)
          return cityLookup.length > 0 ? cityLookup[0] : null
        }
        return null
      },
      set(val) {
        this.$store.dispatch("setEventField", {
          field: "city",
          value: val?.city,
        })
      },
    },

    country() {
      return this.city ? this.city?.country : this.activeEvent?.country
    },

    timezone() {
      return this.city ? this.city?.timezone : this.activeEvent.time_zone
    },
  },
}
</script>

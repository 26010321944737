<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    attach="#members-list"
  >
    <v-card data-testid="container-event-invite">
      <v-card-title>
        <span class="headline">Invite member</span>
      </v-card-title>
      <v-card-text>
        <v-form
          data-vv-scope="form-event-invite"
          data-testid="form-event-invite"
        >
          <v-alert
            :value="true"
            type="error"
            v-if="errors.has('form-event-invite.general_error_field')"
            data-testid="event-invite-general-errors"
          >
            {{ errors.first("form-event-invite.general_error_field") }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="item.email"
                  data-vv-name="email"
                  v-validate="'required|emailWithSpaces'"
                  label="E-mail"
                  class="mt-4"
                  required
                  outlined
                  :error-messages="errors.collect('form-event-invite.email')"
                  data-vv-validate-on="change"
                  data-testid="email"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  label="Role"
                  outlined
                  v-model="item.role"
                  item-value="_key"
                  item-text="name"
                  :items="eventMembersRolesList"
                  :menu-props="{ maxHeight: '400' }"
                  data-vv-name="role"
                  v-validate="'required'"
                  data-vv-validate-on="change"
                  :error-messages="errors.collect(`form-event-invite.role`)"
                  return-object
                  data-testid="role"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>
        <v-btn color="success" text @click.stop="save()" data-testid="btn-send">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import "@/validators/engines/store/event-validator"
import GeneralMixin from "@/mixins/general"

export default {
  name: "event-member-invite-dialog",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [GeneralMixin],

  created() {
    this.$store.dispatch("getEventMembersRolesList", {
      params: { p: 1, pp: 100 },
    })
  },

  data() {
    return {
      defaultItem: {
        email: "",
        role: null,
      },
      item: {
        email: "",
        role: null,
      },
    }
  },

  methods: {
    save() {
      let self = this
      self.$validator.errors.clear("form-event-invite")
      self.$validator.validateAll("form-event-invite").then((result) => {
        if (result) {
          let email = self.item.email
          self.$store
            .dispatch("inviteEventMember", {
              ...{ email: email.trim(), role: self.item?.role?._key },
              ...{ event_id: self.activeEvent._key },
            })
            .then(() => {
              self.item = self.defaultItem
              self.closeDialog()
            })
            .catch((error) => {
              self.handleValidationErrors(error.response, "form-event-invite")
            })
        }
      })
    },
    closeDialog() {
      let self = this
      this.$emit("update:dialog", false)
      setTimeout(function () {
        self.$validator.errors.clear("form-event-invite")
        self.item = self.defaultItem
      }, 20)
    },
  },

  computed: {
    ...mapGetters({
      activeEvent: "activeEvent",
      eventMembersRolesList: "eventMembersRolesList",
    }),
  },
}
</script>

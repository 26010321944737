<template>
  <v-dialog :value="dialog" persistent max-width="500px">
    <v-form
      data-vv-scope="form-bundle-connection"
      key="licenseConnectionCreateUpdate"
      data-testid="form-bundle-connection"
    >
      <v-card>
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-alert
                v-if="errors.has('form-bundle-connection.general_error_field')"
                type="error"
                text
                data-testid="bundle-general-errors"
              >
                {{ errors.first("form-bundle-connection.general_error_field") }}
              </v-alert>
              <v-alert
                v-if="errors.has('form-bundle-connection.event_key')"
                type="error"
                text
                data-testid="bundle-event-key-errors"
              >
                {{ errors.first("form-bundle-connection.event_key") }}
              </v-alert>
            </v-col>

            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="selected_receiver_engine_key"
                :items="enginesReceivers"
                outlined
                label="Display"
                :multiple="false"
                item-value="_key"
                item-text="name"
                :disabled="editedIndex === -1 ? false : true"
                v-validate="'required'"
                :error-messages="
                  errors.collect(`form-bundle-connection.license_registry_key`)
                "
                data-vv-name="license_registry_key"
                data-vv-validate-on="change"
                data-vv-as="Display"
                key="display_engine_key"
                data-testid="display"
              >
                <template v-slot:item="{ item }">
                  {{ item.name }} | {{ getBundleName(item.active_product) }}
                </template>

                <template v-slot:selection="{ item }">
                  {{ item.name }} | {{ getBundleName(item.active_product) }}
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12">
              <SelectEnginesAsync
                v-model="selected_sender_engine_key"
                outlined
                label="Capture"
                item-value="_key"
                item-text="name"
                v-validate="'required'"
                :error-messages="
                  errors.collect(`form-bundle-connection.sender_engine_key`)
                "
                data-vv-name="sender_engine_key"
                data-vv-validate-on="change"
                data-vv-as="Capture"
                field="sender_engine_key"
                :menu-props="{ maxHeight: 304 }"
                :disabled="editedIndex === -1 ? false : true"
                :customFilters="customFilterSenders"
                key="capture_engine_key"
                data-testid="capture"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            @click.native="closeDialog()"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import "@/validators/ip/ip-validator"
import SelectEnginesAsync from "@/components/selects/SelectEnginesAsync"
//import EventsList from "./OLD/EventsList"
import GeneralMixin from "@/mixins/general.js"
import { EngineService } from "@/common/api/api.service"
import StoreMixin from "@/mixins/store/common.js"

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  components: {
    SelectEnginesAsync,
    //EventsList,
  },

  mixins: [GeneralMixin, StoreMixin],

  data() {
    return {
      enginesReceivers: [],
    }
  },

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetEngineCompanyList").then(() => {
      self.$store.dispatch("resetBundleConnectionItem").then(() => {
        self.getItemData().then(() => {
          self.$store.dispatch("saveLoaderAction", false)
        })
      })
    })
  },

  watch: {
    dialog: {
      handler: function (newVal) {
        if (newVal) {
          this.getEnginesReceivers()
        }
        // this.$store.dispatch('resetBundleConnectionItem').then(() => {
        //   this.getEnginesReceivers()
        // })
      },
      immediate: true,
    },
    "activeBundleConnection.receiver_engine_key": function (newVal) {
      if (newVal === this.activeBundleConnection.sender_engine_key) {
        this.selected_sender_engine_key = null
      }
    },
    "activeBundleConnection.sender_engine_key": function (newVal) {
      if (this.activeBundleConnection.receiver_engine_key === newVal) {
        this.selected_sender_engine_key = null
      }
    },
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Add new connection"
        : `<span class="blue-grey--text text--lighten-4">Edit connection</span> ${this.activeBundleConnection._key}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("saveBundleConnectionItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    createConnection(formData) {
      let self = this
      self.$store
        .dispatch("createBundleConnection", formData)
        .then(({ data }) => {
          let params = {
            text: `Registered bundle <strong>${formData.data.name}</strong> was created successfully.`,
            show: true,
          }
          self.closeDialog().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-bundle-connection")
        })
    },
    editConnection(formData) {
      let self = this
      self.$store
        .dispatch("editBundleConnection", formData)
        .then(({ data }) => {
          let params = {
            text: `Connection <strong>${formData.data.name}</strong> was edited successfully.`,
            show: true,
          }
          self.closeDialog().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-bundle-connection")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-bundle-connection")
      self.$validator.validateAll("form-bundle-connection").then((result) => {
        if (result) {
          let receiverEngineKey =
            self.activeBundleConnection.receiver_engine_key
          let senderEngineKey = self.activeBundleConnection.sender_engine_key
          let formData = {
            data: {
              event_key: self.activeEvent._key,
              receiver_engine_key: receiverEngineKey.toString(),
              sender_engine_key: senderEngineKey.toString(),
            },
          }
          if (this.editedIndex > -1) {
            formData["slug"] = self.editedIndex
            self.editConnection(formData)
          } else {
            self.createConnection(formData)
          }
        }
      })
    },
    close() {
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: `/store/bundles/registry` })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
    closeDialog() {
      this.$emit("update:dialog", false)
      this.$emit("data-saved")
      this.$validator.errors.clear("form-bundle-connection")
    },
    getEnginesReceivers() {
      let self = this
      let fetchData = {
        params: {
          p: 1,
          pp: 500,
          keep: "_key,name",
          is_receiver: true,
          event_key: self.activeEvent._key,
        },
      }
      EngineService.get(fetchData)
        .then((response) => {
          self.enginesReceivers = response.data.data.engines
        })
        .catch((err) => {
          console.error("Error on getEnginesWithProducts", err)
        })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeEvent: "activeEvent",
      activeBundleConnection: "activeBundleConnection",
      Pallette: "get_Pallette",
    }),
    customFilterSenders() {
      let customFilterSenders = {
        company_key: this.activeEvent.group_key,
        event_key: this.activeEvent._key,
      }
      if (this.activeBundleConnection.receiver_engine_key) {
        customFilterSenders.exclude = `${this.activeBundleConnection.receiver_engine_key}`
      }
      return customFilterSenders
    },
    selected_receiver_engine_key: {
      get() {
        return this.activeBundleConnection.receiver_engine_key
      },
      set(val) {
        this.$store.commit("SAVE_BUNDLE_CONNECTION_ITEM", {
          receiver_engine_key: val,
        })
      },
    },
    selected_sender_engine_key: {
      get() {
        return this.activeBundleConnection.sender_engine_key
      },
      set(val) {
        this.$store.commit("SAVE_BUNDLE_CONNECTION_ITEM", {
          sender_engine_key: val,
        })
      },
    },
  },
}
</script>

<template>
  <div data-testid="container-connections">
    <v-toolbar>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$can('create', 'Superadmin') || $can('create', 'connections')"
        :color="Pallette.actionButtons.newItem"
        fab
        dark
        absolute
        bottom
        right
        :small="!$vuetify.breakpoint.smAndUp"
        @click="createConnection()"
        data-testid="btn-add-connection"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <DataTableExtended
      :headers="headers"
      :items="bundleConnectionsList"
      item-key="_key"
      class="elevation-0 border"
      :calculate-widths="true"
      :loading="showTableLoader"
      :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
      sortable
      :multi-sort="false"
      :server-items-length="bundleConnectionsCount"
      v-on:init-table-data="getBundleConnectionsList"
      ref="refTableConnectionsBundle"
      data-testid="table-connections"
    >
      <template v-slot:top></template>

      <template v-slot:[`item.license_registry-engine-name`]="{ item }">
        <div data-testid="capture">
          {{ item.receiver_engine ? item.receiver_engine.name : "" }}
        </div>
      </template>

      <template v-slot:[`item.sender_engine_key`]="{ item }">
        <div data-testid="display">
          {{ item.sender_engine ? item.sender_engine.name : "" }}
        </div>
      </template>

      <template v-slot:[`item.menu`]="{ item }">
        <v-btn
          @click.stop="deleteBundleConnection(item)"
          :color="Pallette.actionButtons.delete"
          dark
          fab
          x-small
          v-on="on"
          data-testid="btn-delete-connection"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <div
          v-if="
            $vuetify.breakpoint.smAndUp &&
            ($can('delete', 'Superadmin') || $can('delete', 'connections'))
          "
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="deleteBundleConnection(item)"
                :color="Pallette.actionButtons.delete"
                dark
                fab
                x-small
                v-on="on"
                data-testid="btn-delete-connection"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete connection</span>
          </v-tooltip>
        </div>
      </template>
    </DataTableExtended>

    <v-card-actions>
      <v-spacer />
      <v-btn color="error" text to="/store/events" data-testid="btn-close">
        Close
      </v-btn>
    </v-card-actions>

    <Confirm ref="confirmDialog"></Confirm>
    <v-dialog :value="dialog" persistent max-width="500px">
      <BundleConnectionCrud
        :editedIndex="editedIndex"
        :dialog.sync="dialog"
        @data-saved="getBundleConnectionsList()"
      ></BundleConnectionCrud>
    </v-dialog>
  </div>
</template>

<script>
/**
 * Component for list of registered bundles to show only for superadmin
 */
import { mapGetters } from "vuex"
import Confirm from "@/components/Confirm"
// import VerticalButton from "@/components/buttons/VerticalButton"
// import SortMobile from "@/components/table/SortMobile.vue"
// import SelectEnginesAsync from "@//components/selects/SelectEnginesAsync"
import DataTableExtended from "@/components/table/DataTableExtended.vue"
import BundleConnectionCrud from "./BundleConnectionCrud"
import EventCommonMixin from "@/mixins/event/common.js"

export default {
  components: {
    BundleConnectionCrud,
    //VerticalButton,
    Confirm,
    //SortMobile,
    // SelectEnginesAsync,
    DataTableExtended,
  },

  mixins: [EventCommonMixin],

  data() {
    return {
      headers: [
        {
          text: "Display",
          align: "left",
          value: "license_registry-engine-name",
          sortable: false,
          width: 220,
        },
        // {
        //   text: 'Bundle',
        //   align: 'left',
        //   value: 'license_registry-license-name',
        //   sortable: false,
        //   width: 220
        // },
        {
          text: "Capture",
          align: "left",
          value: "sender_engine_key",
          sortable: false,
        },
        {
          text: "",
          value: "menu",
          sortable: false,
          width: 20,
        },
      ],
      keep: "_key,bundle_key,engine_key,white_list",
      filterByEngine: null,
      dialog: false,
      editedIndex: -1,
    }
  },

  watch: {
    filterByEngine: function () {
      this.getBundleConnectionsList()
    },
    "activeEvent._key": {
      handler: function (newVal) {
        this.getBundleConnectionsList()
      },
      immediate: true,
    },
    dialog: function (val) {
      if (val) {
        this.$store.dispatch("resetBundleConnectionItem").then(() => {})
      }
    },
  },

  created() {
    let self = this
    self.initWebsocketConnection()
  },

  methods: {
    createConnection() {
      this.dialog = true
      this.editedIndex = -1
    },

    // editConnection(item, gotoStepTab) {
    //   this.dialog = true;
    //   this.activeItem = _.cloneDeep(item);
    //   this.gotoStepTab = gotoStepTab;
    //   this.editedIndex = item._key;
    // },

    getBundleConnectionsList: function () {
      let self = this
      if (self.activeEvent._key) {
        let getParams =
          self.$refs.refTableConnectionsBundle.getTableServerParams()
        getParams.event_key = self.activeEvent._key
        self.$store.dispatch("getBundleConnectionsList", { params: getParams })
      }
    },
    deleteBundleConnection(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this connection?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self.$store
              .dispatch("deleteBundleConnection", item)
              .then((response) => {
                if (response.data.status === "success")
                  self.getBundleConnectionsList()
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
    },
  },

  computed: {
    ...mapGetters({
      bundleConnectionsList: "bundleConnectionsList",
      bundleConnectionsCount: "bundleConnectionsCount",
      activeEvent: "activeEvent",
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
    }),
  },
}
</script>

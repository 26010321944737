var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px","attach":_vm.attach},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline"},[_vm._v(" Add member "),(_vm.advanced)?_c('span',[_vm._v("(advanced mode)")]):_vm._e()])]),_c('v-card-text',[_c('v-form',{attrs:{"data-vv-scope":"form-event-members","data-testid":"form-event-member"}},[_c('v-container',[_c('v-row',[(!_vm.advanced)?[_c('v-col',{attrs:{"cols":"12"}},[_c('SelectLocationsAsync',{directives:[{name:"validate",rawName:"v-validate",value:(
                    `requiredAdvanced:${_vm.advanced},location|uniqueMembersEventLocation|required:false`
                  ),expression:"\n                    `requiredAdvanced:${advanced},location|uniqueMembersEventLocation|required:false`\n                  "}],attrs:{"outlined":"","label":"Location","multiple":false,"item-text":"name","error-messages":_vm.errors.collect(`form-event-members.location`),"data-vv-name":"location","data-vv-validate-on":"change","data-vv-as":"location","field":"location","menu-props":{ maxHeight: 304 },"return-object":"","customFilters":_vm.customFilterLocations,"data-testid":"location"},model:{value:(_vm.item.location),callback:function ($$v) {_vm.$set(_vm.item, "location", $$v)},expression:"item.location"}})],1)]:_vm._e(),(_vm.advanced)?[_c('v-col',{attrs:{"cols":"12"}},[_c('SelectEnginesAsync',{directives:[{name:"validate",rawName:"v-validate",value:(
                    `requiredAdvanced:${_vm.advanced},engine|uniqueMembersEvent|required:false`
                  ),expression:"\n                    `requiredAdvanced:${advanced},engine|uniqueMembersEvent|required:false`\n                  "}],attrs:{"outlined":"","label":"Engine","multiple":false,"item-value":"_key","item-text":"name","error-messages":_vm.errors.collect(`form-event-members.engine`),"data-vv-name":"engine","data-vv-validate-on":"change","field":"engine","menu-props":{ maxHeight: 304 },"customFilters":_vm.customFilterEngines,"keep":"_key,name,serial_number","data-testid":"engine"},model:{value:(_vm.item.engine),callback:function ($$v) {_vm.$set(_vm.item, "engine", $$v)},expression:"item.engine"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(
                    `requiredAdvanced:${_vm.advanced},role|required:false`
                  ),expression:"\n                    `requiredAdvanced:${advanced},role|required:false`\n                  "}],attrs:{"label":"Role","outlined":"","items":_vm.eventMembersRolesList,"item-value":"_key","item-text":"name","menu-props":{ maxHeight: '400' },"data-vv-name":"role","data-vv-validate-on":"change","error-messages":_vm.errors.collect(`form-event-members.role`),"return-object":"","data-testid":"role"},model:{value:(_vm.item.role),callback:function ($$v) {_vm.$set(_vm.item, "role", $$v)},expression:"item.role"}})],1)]:_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"mt-0 mb-4",attrs:{"label":"Check permission handshake","hide-details":"","data-testid":"chbx-check-permission-handshake"},model:{value:(_vm.item.output_permission),callback:function ($$v) {_vm.$set(_vm.item, "output_permission", $$v)},expression:"item.output_permission"}})],1),(!_vm.advanced)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","data-testid":"btn-advanced"},on:{"click":function($event){_vm.advanced = true}}},[_vm._v(" Advanced ")])],1):_vm._e()],2)],1)],1)],1),_c('v-card-actions',[(_vm.advanced)?_c('v-btn',{attrs:{"text":"","data-testid":"btn-back"},on:{"click":function($event){_vm.advanced = false}}},[_vm._v(" back ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","data-testid":"btn-close"},nativeOn:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"success","text":"","data-testid":"btn-save"},on:{"click":function($event){$event.stopPropagation();return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
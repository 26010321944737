<template>
  <div>
    <div v-if="cropImg" class="avatar" :style="imgStyles">
      <v-icon @click="dialog = true">camera_alt</v-icon>
    </div>
    <div v-else-if="image" class="avatar" :style="imgStyles">
      <v-icon @click="dialog = true">camera_alt</v-icon>
    </div>

    <!-- Dialog -->
    <v-dialog
      persistent
      no-click-animation
      max-width="400px"
      v-model="dialog"
      dark
      content-class="avatar-upload-dialog"
    >
      <v-card>
        <v-container class="pa-2 text-xs-center" fluid>
          <div v-show="!imgSrc">
            <input
              ref="input"
              type="file"
              name="image"
              accept="image/*"
              @change="browseFile"
              style="display: none"
            />
            <div
              class="avatar-uploader advanced-upload"
              @drop.prevent="dragFile"
              @dragover.prevent
            >
              <span class="message"> Drag and drop your image here </span>
            </div>
            <div class="text-center">or</div>
            <div class="d-flex justify-center">
              <v-btn small text color="primary" @click="onPickupFile">
                browse a photo
              </v-btn>
            </div>
          </div>

          <div>
            <vue-cropper
              v-show="imgSrc"
              ref="cropper"
              alt="Source Image"
              :aspect-ratio="1"
              :src="imgSrc"
              :data="data"
            >
            </vue-cropper>
          </div>

          <v-layout class="pt-2">
            <v-btn small text color="red darken-4" @click="deleteAvatar">
              delete
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              color="success"
              @click.prevent="cropImage"
              :disabled="crop"
            >
              crop
            </v-btn>
            <v-btn small text color="amber accent-4" @click="reset">
              reset
            </v-btn>
            <v-btn
              small
              text
              :color="Pallette.actionButtons.delete"
              @click="dialog = false"
            >
              cancel
            </v-btn>
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import VueCropper from "vue-cropperjs"
import "cropperjs/dist/cropper.css"

export default {
  name: "Avatar",

  props: {
    image: String,
  },

  components: {
    VueCropper,
  },

  data() {
    return {
      dialog: false,
      imgSrc: "",
      cropImg: "",
      data: {
        width: 128,
        height: 128,
      },
      crop: true,
      file: {
        name: "",
      },
    }
  },

  created() {
    window.addEventListener("dragenter", function () {
      let elm = document.querySelector(".avatar-uploader")
      if (elm) {
        elm.classList.add("is-dragover")
      }
    })
    window.addEventListener("dragleave", function () {
      let elm = document.querySelector(".avatar-uploader")
      if (elm) {
        elm.classList.remove("is-dragover")
      }
    })
    window.addEventListener("dragover", function (e) {
      e.preventDefault()
      let elm = document.querySelector(".avatar-uploader")
      if (elm) {
        elm.classList.add("is-dragover")
      }
    })
    window.addEventListener("drop", function (e) {
      e.preventDefault()
      let elm = document.querySelector(".avatar-uploader")
      if (elm) {
        elm.classList.remove("is-dragover")
      }
    })
  },

  methods: {
    onPickupFile() {
      this.$refs.input.click()
    },
    browseFile(e) {
      e.stopPropagation()
      e.preventDefault()
      const file = e.target.files[0]
      this.file.name = file.name
      this.setImage(file)
    },
    dragFile(e) {
      let file = e.dataTransfer.files[0]
      this.file.name = file.name
      this.setImage(file)
    },
    setImage(file) {
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file")
        return
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imgSrc = event.target.result
          this.$refs.cropper.replace(event.target.result)
          this.crop = false
        }
        reader.readAsDataURL(file)
      } else {
        alert("Sorry, FileReader API not supported")
      }
    },
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.dialog = false
      this.$refs.input.value = ""
      let canvas = this.$refs.cropper.getCroppedCanvas()
      this.createImage(canvas)
    },
    createImage(canvas) {
      canvas.toBlob((blob) => {
        let file = new File([blob], this.file.name, {
          lastModified: 1534584790000,
          type: blob["type"],
          size: blob["size"],
        })
        this.$emit("getCroppedImage", file)
      })
    },
    reset() {
      this.dialog = false
      this.cropImg = ""
      this.imgSrc = ""
      this.$refs.input.value = ""
      this.crop = true
    },
    deleteAvatar() {
      this.dialog = false
      this.cropImg = ""
      this.imgSrc = ""
      this.$refs.input.value = ""
      this.crop = true
      this.$emit("deleteImage")
    },
  },

  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
    }),
    imgStyles() {
      if (this.cropImg) {
        return { backgroundImage: `url(${this.cropImg})` }
      } else if (this.image) {
        return { backgroundImage: `url(${this.image})` }
      }
      return {}
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  margin: 0 auto;
  position: relative;
  display: block;
  width: 128px;
  height: 128px;
  background-color: rgba(255, 255, 255, 0.3);
  background-size: cover;
  background-repeat: no-repeat;
  border: 2px solid #dddddd;
  border-radius: 50%;
  transition: all ease-in-out 0.5s;
  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .v-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
}

.avatar-uploader {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 15px auto;
  background-color: #272727;

  &.advanced-upload {
    border: 2px dashed #7d7d7d;
    background-color: #ffffff;
    -webkit-transition: border-offset 0.15s ease-in-out,
      background-color 0.15s linear;
    transition: border-offset 0.15s ease-in-out, background-color 0.15s linear;
  }

  &.is-dragover {
    border: 2px dashed #000;
    background-color: #dddddd;
    transition: border 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }

  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 128px;
    text-align: center;
    color: #272727;
  }
}

.avatar-upload-dialog .v-card {
  background-color: #272727;
}
</style>

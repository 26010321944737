<template>
  <v-dialog v-model="dialog" persistent max-width="600px" :attach="attach">
    <v-card>
      <v-card-title>
        <div class="headline">
          Add member <span v-if="advanced">(advanced mode)</span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          data-vv-scope="form-event-members"
          data-testid="form-event-member"
        >
          <v-container>
            <v-row>
              <template v-if="!advanced">
                <v-col cols="12">
                  <SelectLocationsAsync
                    v-model="item.location"
                    outlined
                    label="Location"
                    :multiple="false"
                    item-text="name"
                    v-validate="
                      `requiredAdvanced:${advanced},location|uniqueMembersEventLocation|required:false`
                    "
                    :error-messages="
                      errors.collect(`form-event-members.location`)
                    "
                    data-vv-name="location"
                    data-vv-validate-on="change"
                    data-vv-as="location"
                    field="location"
                    :menu-props="{ maxHeight: 304 }"
                    return-object
                    :customFilters="customFilterLocations"
                    data-testid="location"
                  />
                </v-col>
              </template>
              <template v-if="advanced">
                <v-col cols="12">
                  <SelectEnginesAsync
                    v-model="item.engine"
                    outlined
                    label="Engine"
                    :multiple="false"
                    item-value="_key"
                    item-text="name"
                    v-validate="
                      `requiredAdvanced:${advanced},engine|uniqueMembersEvent|required:false`
                    "
                    :error-messages="
                      errors.collect(`form-event-members.engine`)
                    "
                    data-vv-name="engine"
                    data-vv-validate-on="change"
                    field="engine"
                    :menu-props="{ maxHeight: 304 }"
                    :customFilters="customFilterEngines"
                    keep="_key,name,serial_number"
                    data-testid="engine"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Role"
                    outlined
                    v-model="item.role"
                    :items="eventMembersRolesList"
                    item-value="_key"
                    item-text="name"
                    :menu-props="{ maxHeight: '400' }"
                    data-vv-name="role"
                    v-validate="
                      `requiredAdvanced:${advanced},role|required:false`
                    "
                    data-vv-validate-on="change"
                    :error-messages="errors.collect(`form-event-members.role`)"
                    return-object
                    data-testid="role"
                  ></v-select>
                </v-col>
              </template>
              <v-col cols="12" class="pt-0">
                <v-checkbox
                  v-model="item.output_permission"
                  label="Check permission handshake"
                  hide-details
                  class="mt-0 mb-4"
                  data-testid="chbx-check-permission-handshake"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" v-if="!advanced">
                <v-btn
                  block
                  @click="advanced = true"
                  data-testid="btn-advanced"
                >
                  Advanced
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="advanced"
          text
          @click="advanced = false"
          data-testid="btn-back"
        >
          back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click.native="closeDialog()"
          data-testid="btn-close"
        >
          Close
        </v-btn>
        <v-btn color="success" text @click.stop="save()" data-testid="btn-save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash"
import SelectEnginesAsync from "@/components/selects/SelectEnginesAsync"
import SelectLocationsAsync from "@/components/selects/SelectLocationsAsync"
import { mapGetters } from "vuex"
import "@/validators/engines/store/event-validator"

export default {
  name: "event-member-dialog",

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: String,
      default: "#members-list",
    },
  },

  components: {
    SelectEnginesAsync,
    SelectLocationsAsync,
  },

  created() {
    this.$store.dispatch("getEventMembersRolesList", {
      params: { p: 1, pp: 100 },
    })
  },

  data() {
    return {
      defaultItem: {
        engine: null,
        role: null,
        location: null,
        output_permission: false,
      },
      item: {
        engine: null,
        role: null,
        location: null,
        output_permission: false,
      },
      advanced: false,
    }
  },

  methods: {
    save() {
      let self = this
      self.$validator.errors.clear("form-event-members")
      self.$validator.validateAll("form-event-members").then((result) => {
        if (result) {
          self.$store
            .dispatch("createEventMember", {
              slug: {
                event_key: self.activeEvent._key,
              },
              data: {
                location_key: !self.advanced ? self.item?.location?._key : null,
                engine_key:
                  !self.advanced && self.item?.location
                    ? self.item?.location?.engine?._key
                    : self.item?.engine?._key,
                role:
                  !self.advanced && self.item?.location
                    ? self.item?.location?.role?._key
                    : self.item?.role?._key,
                output_permission: self.item.output_permission,
              },
            })
            .then(() => {
              self.closeDialog()
            })
        }
      })
    },
    closeDialog() {
      let self = this
      this.$emit("update:dialog", false)
      setTimeout(function () {
        self.$validator.errors.clear("form-event-members")
        self.item = _.cloneDeep(self.defaultItem)
        self.$root.$emit("event-member-change")
      }, 20)
    },
  },

  computed: {
    ...mapGetters({
      activeEvent: "activeEvent",
      eventMembersRolesList: "eventMembersRolesList",
    }),
    customFilterEngines() {
      let enginesKeys = this.activeEvent.members.map((m) => m.engine_key)
      return { exclude: enginesKeys }
    },
    customFilterLocations() {
      return this.activeEvent?.group_key
        ? { group_key: this.activeEvent?.group_key }
        : {}
    },
  },
}
</script>

<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      :search-input.sync="filterSearch"
      loading="filterEnginesLoading"
      :items="engineLocationsList"
      :cache-items="true"
      @select="selectValue"
      v-model="val"
      ref="autocompleteLocations"
    >
      <template v-slot:append-item>
        <div
          v-if="lastPage && !filterSearch"
          v-observe-visibility="{
            callback: visibilityChanged,
          }"
          class="selectPagination"
        >
          <span class="pa-2">Loading more items ...</span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "SelectLocationsAsync",

  inheritAttrs: false,

  props: {
    value: {
      type: [Number, String, Object],
    },
    keep: {
      type: String,
      default: "_key,name,engine,role",
    },
    customFilters: {
      type: Object,
    },
  },

  data: () => ({
    filterSearch: null,
    selectPagination: {
      p: 1,
      pp: 500,
    },
  }),

  mounted: function () {
    this.fetchItems()
  },

  watch: {
    selected(val) {
      this.$emit("update:selected", val)
    },
    filterSearch() {
      this.selectPagination.p = 1
      this.fetchItems()
    },
    value: {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          // @todo return later
          // this.getDataForSelected()
        }
      },
      immediate: true,
    },
    customFilters: function () {
      this.fetchItems(true)
    },
  },

  methods: {
    visibilityChanged(e) {
      if (this.lastPage) {
        e && this.nextPage()
      }
    },
    startPage() {
      this.selectPagination.p = 1
    },
    nextPage() {
      let maxPages = Math.ceil(
        this.engineLocationsCount / this.selectPagination.pp
      )
      if (this.selectPagination.p <= maxPages - 1) {
        this.filterEnginesLoading = true
        this.selectPagination.p += 1
        this.fetchItems()
      }
      this.filterEnginesLoading = false
    },
    fetchItems(resetCache = false) {
      if (resetCache) {
        this.$refs.autocompleteLocations.cachedItems = []
        this.$store.dispatch("resetEngineLocations")
      }
      let fetchData = {
        params: {
          p: this.selectPagination.p,
          // @todo later return this when will be possible to fetch all companies list
          // pp: this.selectPagination.pp,
          pp: 500,
          keep: this.keep,
        },
      }
      //if (this.filterSearch) fetchData.params.search = this.filterSearch
      if (this.filterSearch) fetchData.search = this.filterSearch
      if (this.customFilters)
        fetchData.params = { ...fetchData.params, ...this.customFilters }
      this.$store.dispatch("getEngineLocationsList", fetchData)
    },
    selectValue() {
      this.filterSearch = ""
    },
  },

  computed: {
    ...mapGetters({
      engineLocationsList: "engineLocationsList",
      engineLocationsCount: "engineLocationsCount",
    }),
    lastPage() {
      let flag =
        this.engineLocationsCount -
          this.selectPagination.p * this.selectPagination.pp >
        0
      return flag
    },
    val: {
      get() {
        return this.value
      },
      set() {},
    },
  },
}
</script>

<template>
  <div id="app">
    <v-card>
      <v-toolbar>
        <span class="headline" v-html="getTitle()"></span>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-stepper v-model="bundleWizard" vertical>
          <v-form
            ref="form"
            data-vv-scope="form-bundle-registered"
            key="buyBundleForm"
          >
            <v-stepper-step
              :complete="bundleWizard > 1"
              step="1"
              :editable="isEventType ? false : true"
            >
              Select engine
              <small
                >This will be stream receiver engine who is this bundle
                for</small
              >
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-card class="mb-4">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" class="pt-4">
                      <SelectEnginesAsync
                        v-model="activeOrderRegistered.engine"
                        outlined
                        label="Engine"
                        :multiple="false"
                        item-value="_key"
                        item-text="name"
                        v-validate="'required'"
                        :error-messages="
                          errors.collect(`form-bundle-registered.engine`)
                        "
                        data-vv-name="engine"
                        data-vv-validate-on="change"
                        field="engine"
                        :menu-props="{ maxHeight: 304 }"
                        return-object
                        :disabled="editedIndex === -1 ? false : true"
                      />
                    </v-col>
                  </v-row>
                  <v-alert
                    v-if="
                      errors.has('form-bundle-registered.general_error_field')
                    "
                    type="error"
                    text
                  >
                    {{
                      errors.first("form-bundle-registered.general_error_field")
                    }}
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text to="/store/bundles" color="error">Cancel</v-btn>
                  <v-btn
                    key="saveEngine"
                    color="green"
                    text
                    @click="buyAndAddEvent()"
                    v-if="isEventType"
                  >
                    Buy & Add event
                  </v-btn>

                  <v-btn
                    v-if="bundleRequireIpsWhitelist"
                    key="saveEngineAndNext"
                    color="green"
                    text
                    @click="saveEngineAndNext()"
                  >
                    Next
                  </v-btn>

                  <v-btn
                    v-if="!bundleRequireIpsWhitelist"
                    key="saveEngineBuyClose"
                    color="orange"
                    text
                    @click="buyAndClose()"
                  >
                    Buy & Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <template v-if="bundleRequireIpsWhitelist">
              <v-stepper-step :complete="bundleWizard === 2" step="2">
                IP lists
                <small>List of IPs between which streams can be sent</small>
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card class="mb-12">
                  <v-card-text>
                    <v-combobox
                      v-model="activeOrderRegistered.white_list"
                      :items="itemsIps"
                      chips
                      outlined
                      label="IP whitelist"
                      multiple
                      append-outer-icon="mdi-plus"
                      @click:append-outer="dialogWhitelistIps = true"
                      append-icon="false"
                      readonly
                    >
                      <template
                        v-slot:selection="{ attrs, item, select, selected }"
                      >
                        <v-chip
                          v-bind="attrs"
                          :input-value="selected"
                          close
                          @click="select"
                          @click:close="removeIP(item)"
                        >
                          <strong>{{ item }}</strong>
                        </v-chip>
                      </template>
                    </v-combobox>
                    <v-alert
                      v-if="
                        errors.has('form-bundle-registered.general_error_field')
                      "
                      type="error"
                      text
                    >
                      {{
                        errors.first(
                          "form-bundle-registered.general_error_field"
                        )
                      }}
                    </v-alert>
                    <AddSingleTextDialog
                      :dialog.sync="dialogWhitelistIps"
                      @save-field="addNewIp"
                      title="Add IP to whitelist"
                      field="IP"
                      rules="required|validIp"
                    ></AddSingleTextDialog>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text to="/store/bundles" color="error">Cancel</v-btn>
                    <v-btn
                      key="buyAndClose"
                      color="green"
                      text
                      @click="buyAndClose()"
                    >
                      Buy & Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </template>
          </v-form>

          <template v-if="isEventType">
            <v-stepper-step :complete="bundleWizard === 2" step="2">
              Add event (optional)
              <small>Add event related to current bundle</small>
            </v-stepper-step>
            <v-stepper-content step="2" min-height="300px">
              <v-card class="mb-12" min-height="500px">
                <v-card-text>
                  <EventSettings
                    :fromAddBundleWizard="true"
                    @event-saved="bundleWizard = 3"
                  />
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </template>

          <template v-if="isEventType">
            <v-stepper-step :complete="bundleWizard === 3" step="3">
              Add connections (optional)
              <small>Add connections for previously added event</small>
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card class="mb-12">
                <v-card-text>
                  <BundleConnectionsList editedIndex="" />
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </template>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SelectEnginesAsync from "@/components/selects/SelectEnginesAsync"
import EventSettings from "@/views/store/events/EventSettings"
import BundleConnectionsList from "@/views/store/events/BundleConnectionsList"
import AddSingleTextDialog from "@/components/commonComponents/AddSingleTextDialog"
import GeneralMixin from "@/mixins/general.js"
import _ from "lodash"

export default {
  props: {
    bundleIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  components: {
    SelectEnginesAsync,
    EventSettings,
    BundleConnectionsList,
    AddSingleTextDialog,
  },

  mixins: [GeneralMixin],

  data() {
    return {
      bundleWizard: 1,
      editedIndex: -1,
      absolute: true,
      overlay: true,
      reveal: false,
      dialogWhitelistIps: false,
      itemsIps: [],
    }
  },

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    //self.getBundlesList()
    self.$store
      .dispatch("getBundleItem", {
        slug: this.bundleIndex,
      })
      .then(() => {
        self.$store.dispatch("resetOrderRegisteredItem").then(() => {
          self.$store
            .dispatch("saveOrderRegisteredItem", { bundle: this.activeBundle })
            .then(() => {
              self.$store.dispatch("setBundleTypes").then(() => {
                self.getItemData().then(() => {
                  self.$store.dispatch("saveLoaderAction", false)
                })
              })
            })
        })
      })
  },

  methods: {
    removeIP(item) {
      this.activeOrderRegistered.white_list.splice(
        this.activeOrderRegistered.white_list.indexOf(item),
        1
      )
      this.activeOrderRegistered.white_list = [
        ...this.activeOrderRegistered.white_list,
      ]
    },
    addNewIp(item) {
      this.activeOrderRegistered.white_list.push(item)
    },
    getTitle() {
      return `<span class="blue-grey--text text--lighten-4">Buy bundle</span> ${this.activeBundle.name}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getOrderRegisteredItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    saveEngineStep() {
      let self = this
      self.$validator.errors.clear("form-bundle-registered")
      self.$validator
        .validate("form-bundle-registered.engine")
        .then((isValid) => {
          if (isValid) {
            self.bundleWizard = 2
          }
        })
    },

    saveEngineAndNext() {
      let self = this

      self.$validator.errors.clear("form-bundle-registered")
      self.$validator
        .validate("form-bundle-registered.engine")
        .then((isValid) => {
          console.log("saveEngineAndNext", isValid)
          if (isValid) {
            self.bundleWizard = 2
          }
        })
    },
    saveEngineBuyClose() {
      let self = this
      self.$validator.errors.clear("form-bundle-registered")
      self.$validator
        .validate("form-bundle-registered.engine")
        .then((isValid) => {
          if (isValid) {
            self.save()
          }
        })
    },
    closeWizard() {},
    buyAndAddEvent() {
      let self = this
      self.save().then(() => {
        let msg =
          self.editedIndex === -1
            ? `Bundle <strong>${self.activeOrderRegistered?.name}</strong> was buyed successfully.`
            : `Bundle <strong>${self.activeOrderRegistered?.name}</strong> was edited successfully.`
        let params = {
          text: msg,
          show: true,
        }
        this.getValidRegisteredBundles()
        self.bundleWizard = 2
      })
    },
    createRegisteredBundle(formData) {
      let self = this
      return new Promise((resolve, reject) => {
        self.$store
          .dispatch("createOrderRegistered", formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            self.handleValidationErrors(response, "form-bundle-registered")
            reject(response)
          })
      })
    },
    editRegisteredBundle(formData) {
      let self = this
      return new Promise((resolve, reject) => {
        self.$store
          .dispatch("editEvent", formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            self.handleValidationErrors(response, "form-bundle-registered")
            reject(response)
          })
      })
    },
    save() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$validator.errors.clear("form-bundle-registered")
        self.$validator.validateAll("form-bundle-registered").then((result) => {
          if (result) {
            let formData = {
              data: {},
            }
            if (self.editedIndex === -1) {
              formData.data = {
                bundle_key: self.activeOrderRegistered.bundle._key,
                engine_key: self.activeOrderRegistered.engine._key,
              }
            }
            if (self.bundleRequireIpsWhitelist) {
              formData.data.white_list =
                self.activeOrderRegistered.white_list || []
            }
            let prms
            if (this.editedIndex > -1) {
              formData["slug"] = self.editedIndex
              prms = self.editRegisteredBundle(formData)
            } else {
              prms = self.createRegisteredBundle(formData)
            }
            prms.then((data) => {
              resolve(_.get(data, "data.data._key"))
            })
          }
        })
      })
    },
    buyAndClose() {
      let self = this
      self.save().then(() => {
        let msg =
          self.editedIndex === -1
            ? `Bundle <strong>${self.activeBundle?.name}</strong> was buyed successfully.`
            : `Bundle <strong>${self.activeBundle?.name}</strong> was edited successfully.`
        let params = {
          text: msg,
          show: true,
        }
        self.close().then(() => {
          self.$root.$emit("snackbar", params)
        })
      })
    },
    close() {
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: `/store/bundles/` })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
    getValidRegisteredBundles() {
      let self = this
      self.$store.dispatch("resetOrderRegisteredList").then(() => {
        self.$store.dispatch("getOrdersRegisteredList", {
          params: {
            p: 1,
            pp: 500,
            group_key: self.activeEvent.group_key,
          },
        })
      })
    },
  },

  computed: {
    ...mapGetters({
      activeOrderRegistered: "activeOrderRegistered",
      bundleTypes: "bundleTypes",
      activeBundle: "activeBundle",
      activeEvent: "activeEvent",
    }),

    bundleRequireIpsWhitelist() {
      return (
        this.activeOrderRegistered?.bundle?._key &&
        this.bundleTypeObj?.is_white_list
      )
    },

    bundleTypeObj: function () {
      return this.bundleTypes.find(
        (l) => l._key === this.activeOrderRegistered?.bundle?.type_key
      )
    },

    isEventType() {
      return this.bundleTypeObj?.name === "External Event"
    },
  },
}
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>

<template>
  <div id="members-list">
    <v-toolbar>
      Members
      <v-spacer></v-spacer>
      <v-btn
        dark
        class="mb-2 mr-2"
        :color="Pallette.actionButtons.edit"
        @click="inviteMember()"
        v-if="activeEvent._key && $can('create', 'events_invites')"
        data-testid="btn-send-invite"
      >
        <v-icon left> mdi-send </v-icon>
        Send invite
      </v-btn>

      <v-btn
        dark
        class="mb-2"
        :color="Pallette.actionButtons.newItem"
        @click="addMember()"
        data-testid="btn-new-member"
      >
        <v-icon left> mdi-plus </v-icon>
        New Member
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="membersEvent"
      class="elevation-0 border"
      :search="search"
      :loading="showTableLoader"
      :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
      :options.sync="options"
      item-key="_key"
      :page.sync="options.page"
      :items-per-page.sync="options.itemsPerPage"
      :server-items-length="membersEvent.length"
      :footer-props="footerOptions"
      sortable
      :multi-sort="false"
      @pagination="onPagination"
      data-testid="table-event-members"
    >
      <template v-slot:top>
        <!--        <v-row class="pa-3">-->
        <!--          <v-col cols="12" md="6">-->
        <!--            <v-text-field-->
        <!--              outlined-->
        <!--              v-model="search"-->
        <!--              append-icon="mdi-magnify"-->
        <!--              label="Search"-->
        <!--              single-line-->
        <!--              hide-details-->
        <!--              :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"-->
        <!--            ></v-text-field>-->
        <!--          </v-col>-->
        <!--          <v-col cols="12" md="6" v-if="$can('read', 'Superadmin')">-->
        <!--            <CompanyFilter :hideDetails="true" v-on:update:selected="onCompanyFilterChange($event)"/>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div data-testid="member-engine-name">
          {{ item.engine ? item.engine.name : "" }}
        </div>
      </template>
      <template v-slot:[`item.serial_number`]="{ item }">
        <div data-testid="member-engine-serial-number">
          {{ item.engine ? item.engine.serial_number : "" }}
        </div>
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.role"
          large
          @save="save(item)"
          @cancel="cancel"
          data-testid="member-role-menu"
        >
          <div class="td-event-role" data-testid="member-role">
            {{
              item.role && item.role.hasOwnProperty("name")
                ? item.role.name
                : "---"
            }}
            <v-icon small class="pb-1 pl-1">mdi-pencil</v-icon>
          </div>
          <template v-slot:input>
            <v-select
              outlined
              v-model="item.role"
              :items="eventMembersRolesList"
              item-value="_key"
              item-text="name"
              :menu-props="{ maxHeight: '400' }"
              data-vv-name="role"
              v-validate="'required'"
              data-vv-validate-on="change"
              return-object
              data-testid="select-member-role"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.output_permission`]="{ item }">
        <v-chip
          @click="updateOutputPermission(item)"
          small
          :color="
            item.output_permission
              ? Pallette.statusChips.active
              : Pallette.statusChips.notActive
          "
          text-color="white"
          class="chip-status-webengine"
          data-testid="member-output-permission"
        >
          {{ item.output_permission ? "On" : "Off" }}
        </v-chip>
      </template>

      <template v-slot:[`item.menu`]="{ item }">
        <div
          class="text-center d-flex align-center justify-end"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-tooltip top v-if="item.output_permission && !item.output_state">
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="permissionHandshakeOutput(item)"
                :color="Pallette.actionButtons.play"
                dark
                fab
                x-small
                v-on="on"
                style="margin: 0 auto"
                data-testid="btn-member-permission-handshake"
              >
                <v-icon>mdi-hand-back-right-outline</v-icon>
              </v-btn>
            </template>
            <span>Allow Output Permission</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click.stop="deleteMember(item)"
                :color="Pallette.actionButtons.delete"
                dark
                fab
                x-small
                v-on="on"
                data-testid="btn-delete-member"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete member</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <EventMemberDialog :dialog.sync="dialogAddMember" />
    <EventMemberInviteDialog :dialog.sync="dialogInviteMember" />

    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import EventMemberDialog from "./EventMemberDialog"
import EventMemberInviteDialog from "./EventMemberInviteDialog"
import Confirm from "@/components/Confirm"

export default {
  components: {
    EventMemberDialog,
    EventMemberInviteDialog,
    Confirm,
  },

  data() {
    return {
      selectedEnginesKeys: [],
      search: "",
      loading: false,
      options: {
        sortBy: ["name", "serial_number"],
        page: 1,
        itemsPerPage: 10,
        group_key: null,
      },
      headers: [
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        {
          text: "Serial number",
          value: "serial_number",
          sortable: false,
        },
        {
          text: "Role",
          value: "role",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
        {
          text: "Permission",
          value: "output_permission",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: 110,
        },
      ],
      activeActionTab: null,
      filters: {
        app_version: [],
      },
      footerOptions: {
        "items-per-page-options": [5, 10, 15, 20, 30],
      },
      dialogAddMember: false,
      dialogInviteMember: false,
    }
  },

  created() {
    this.$store.dispatch("getEventMembersRolesList", {
      params: { p: 1, pp: 100 },
    })
  },

  methods: {
    save(item) {
      let self = this
      self.$store
        .dispatch("updateEventMember", {
          slug: {
            member_key: item._key,
            event_key: self.activeEvent._key,
          },
          data: {
            engine_key: item.engine?._key,
            role: item?.role?._key,
          },
        })
        .then(() => {
          self.$root.$emit("event-member-change")
        })
    },
    cancel() {},
    onPagination() {
      this.selectedEnginesKeys = this.activeEvent.members || []
    },
    addMember() {
      this.dialogAddMember = true
    },
    deleteMember(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete member?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self.$store
              .dispatch("deleteEventMember", {
                slug: {
                  member_key: item._key,
                  event_key: self.activeEvent._key,
                },
              })
              .then(() => {
                self.$root.$emit("event-member-change")
              })
          }
        })
    },
    inviteMember() {
      this.dialogInviteMember = true
    },
    permissionHandshakeOutput(item) {
      let self = this
      self.$store
        .dispatch("handshakeEventMember", {
          slug: {
            member_key: item._key,
            event_key: self.activeEvent._key,
          },
        })
        .then(() => {
          self.$root.$emit("event-member-change")
        })
    },
    updateOutputPermission(item) {
      let self = this
      let data = {
        slug: {
          member_key: item._key,
          event_key: self.activeEvent._key,
        },
        data: {
          engine_key: item.engine_key,
          role: item.role?._key,
          output_permission: !item.output_permission,
        },
      }
      self.$store
        .dispatch("updateEventMember", data)
        .then(() => {
          self.$root.$emit("event-member-change")
        })
        .catch((error) => {
          console.log("error on updateEventMember", error.response)
        })
    },
  },

  computed: {
    ...mapGetters({
      activeEvent: "activeEvent",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      engines: "getEnginesCompanyList",
      enginesCompanyListCount: "enginesCompanyListCount",
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      eventMembersRolesList: "eventMembersRolesList",
    }),
    membersEvent() {
      return this.activeEvent.members
    },
  },
}
</script>

<style lang="scss">
.td-event-role {
  vertical-align: center;

  .v-icon {
    visibility: hidden;
  }

  &:hover {
    text-decoration: dashed underline;

    .v-icon {
      visibility: visible;
    }
  }
}
</style>
